.input_sign input {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  border-radius: 19px;
  box-sizing: border-box;
  height: 53px;
}

#sign_advance_ul_li li {
  border: 1px solid #27378e;
  margin: 20px;
  padding: 20px;
  box-sizing: content-box;
  border-radius: 19px;
}
#sign_advance_ul_li div {
  margin: 5px;
}
