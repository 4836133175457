input,
textarea {
  display: block;
  padding: 10px;
}

input,
select,
textarea {
  width: 100%;
  vertical-align: top;

  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  appearance: none;
  font-weight: 100;
}

input:focus,
select:focus,
textarea:focus {
  box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.2);
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="checkbox"] {
  width: 35px;
  height: 35px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  border-radius: 8px !important;
  box-shadow: 1px 5px 10px rgb(0 0 0 / 64%);
  padding: 0;
  border: none;
  margin-bottom: 5px;
  background: #f1f4fb url(../../../icon-checked.png) 50% -100% no-repeat;
}

input[type="checkbox"]:checked {
  background-position: 50% 50%;
}

.form__element .form__input input:hover,
.form__element .form__input select:hover,
.form__element .form__input textarea:hover,
.form__element.form__element--file .form__input input,
.form__element.form__element--file .form__input select,
.form__element.form__element--file .form__input textarea,
.form__element.form__element--focused .form__input input,
.form__element.form__element--focused .form__input select,
.form__element.form__element--focused .form__input textarea,
input[type="checkbox"]:hover,
input[type="radio"]:hover {
  box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.2);
}

.form__element {
  position: relative;
  display: block;
  margin-bottom: 5px;
}

.form__element:hover {
  cursor: text;
}

.form__element .form__title,
.form__element .form__title__notfocus {
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  left: 0;
  transition: 0.2s ease;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  opacity: 0.7;
  top: 0;
  -webkit-transform: scale(0.85);
  transform: scale(0.85);
}

.form__element .form__title__notfocus {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.form__element .form__input {
  position: relative;
  z-index: 2;
}

.form__element .form__input input,
.form__element .form__input select,
.form__element .form__input textarea {
  border: 0;
  border-radius: 30px;
  box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.7);
  padding: 30px 0 30px 30px;
}

.form__element .form__input button {
  position: absolute;
  top: 50%;
  right: 0%;
  -webkit-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  margin-right: 10px;
  background-color: #11dfc3;
  border-radius: 25px;
  padding: 20px 30px;
  z-index: 5;
}

.form__element label {
  font-size: 14px;
  color: #1d008e;
}
